import React from "react";
import * as styles from "./Locations.module.scss";

const LOCATIONS = [
  {
    title: "Boston, Massachusetts, USA",
    description:
      "Boston is one of the oldest municipalities in the United States, founded on the Shawmut Peninsula in 1630 by Puritan settlers from the English town of the same name. Its rich history attracts many tourists, with Faneuil Hall alone drawing more than 20 million visitors per year.",
    className: styles.boston,
  },
  {
    title: "Tulsa, Oklahoma, USA",
    description:
      "Tulsa is the second-largest city in the state of Oklahoma and 47th-most populous city in the United States. Historically, a robust energy sector fueled Tulsa’s economy; however, today the city has diversified and leading sectors include finance, aviation, telecommunications and technology.",
    className: styles.tulsa,
  },
  {
    title: "Lviv, Ukraine",
    description:
      "Lviv is the largest city in Western Ukraine, and the sixth-largest in Ukraine, with a population of 717,510. Lviv emerged as the centre of the historical regions of Red Ruthenia and Galicia in the 14th century, superseding Halych, Chełm, Belz and Przemyśl.",
    className: styles.lviv,
  },
];

const Locations = ({}) => {
  return (
    <>
      {LOCATIONS.map((location, i) => (
        <div className={`${styles.location} ${location.className}`} key={i}>
          <div className={styles.imgCity} />
          <div className={styles.imgOffice} />
          <div className={styles.card}>
            <div className={styles.title}>{location.title}</div>
            <div className={styles.description}>{location.description}</div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Locations;
