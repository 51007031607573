import React, { useState, useRef, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import cn from "classnames";
import ReactPlayer from "react-player";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import { useScreenSize } from "../../hooks";
import { SCREEN_TABLET } from "../../constants";
import { animateOnScroll } from "../../services/animationService";
import * as styles from "./CareersAtSteer.module.scss";
import UpperSection from "../Shared/UpperSection";
import Card from "../Shared/Card/Card";
import JobBoard from "./JobBoard";
import {
  iconFamily,
  iconOpenCommunication,
  iconRespect,
  iconNotComplacent,
  iconDEIBCouncil,
  iconInclusiveHiring,
  iconTrainingAndEducation,
  iconFeedingBottle,
  iconPalm,
  iconHandshake,
  iconBooks,
  iconGift,
  iconLineGrey,
  iconLine01,
  iconStep01,
  iconRecruiterScreen,
  iconLine02,
  iconStep02,
  iconStep02Grey,
  iconManagerScreen,
  iconLine03,
  iconStep03,
  iconStep03Grey,
  iconFunctionalInterview,
  iconLine04,
  iconStep04,
  iconStep04Grey,
  iconTechnicalInterview,
  iconLine042,
  iconStep042,
  iconHomeAssessment,
  iconLine05,
  iconStep05,
  iconStep05Grey,
  iconReferenceCheck,
  iconStep06,
  iconStep06Grey,
  iconOffer,
} from "../../assets/icons/Careers";

import {
  imgSuccess1,
  imgSuccess2,
  imgSuccess3,
  imgSuccess4,
  imgSuccess5,
  imgVideoPreview,
  imgVideoPreviewMob,
  imgSuccess3Tab,
  imgSuccess4Tab,
  imgSuccess5Tab,
  imgSuccess1Mob,
  imgSuccess2Mob,
  imgSuccess3Mob,
  imgCarousel1,
  imgCarousel2,
  imgCarousel3,
  imgCarousel4,
  imgCarousel5,
  imgCarousel6,
  imgCarousel7,
  imgCarousel8,
  imgCarousel9,
  imgCarousel10,
  imgCarousel11,
  imgCarousel12,
  imgCarousel13,
  imgCarousel14,
  imgCarousel15,
  imgCarousel16,
  imgCarousel17,
  imgCarousel18,
  imgCarousel19,
  imgCarousel20,
  imgCarousel21,
  imgCarousel22,
  imgCarousel23,
  imgCarousel24,
  imgCarousel25,
  imgCarousel26,
  imgCarousel27,
  imgCarousel28,
  imgCarousel29,
  imgCarousel30,
  imgCarousel31,
  imgCarousel32,
  imgCarousel33,
  imgCarousel34,
  imgCarousel35,
} from "../../assets/images/Careers";

const OUR_VALUES_CARDS = [
  {
    title: "Collective excellence",
    description:
      "We work together with passion and empathy, placing people first. Our success comes from collaborative efforts driven by excellence. In this supportive environment, everyone is valued and empowered. We celebrate achievements that benefit our team, customers, and community. We hold ourselves accountable, fostering responsibility, and prioritize transparency and open communication for an inclusive journey to our success.",
    icon: iconFamily,
  },
  {
    title: "Data-driven results",
    description:
      "We are dedicated to fostering a data-driven culture, acknowledging the pivotal role of data in guiding decisions and fostering continuous improvement. Cultivating a culture that esteems and leverages data insights is paramount. We proactively utilize data to shape strategies, refine products, and attain operational excellence, ensuring that our decisions are firmly rooted in evidence.",
    icon: iconRespect,
  },
  {
    title: "Elevating our customers",
    description:
      "We are committed to prioritizing our customers' needs, surpassing expectations, and fostering enduring connections. By delivering exceptional tools and offering educational support, we empower our customers to achieve greater success within their businesses. Actively soliciting and valuing customer feedback is fundamental to refining our solutions and enriching their overall experience.",
    icon: iconOpenCommunication,
  },
  {
    title: "Extraordinary outcomes",
    description:
      "We redefine conventional beliefs by embracing creative problem-solving techniques and transforming challenges into pathways for progress. Our proactive approach sees obstacles as stepping stones to success. Utilizing data-driven insights informs our decision-making, fostering a culture grounded in ownership and creativity. Through consistent adaptation and excellence in a dynamic environment, we aim to achieve superior results and generate outcomes previously unseen in the industry.",
    icon: iconNotComplacent,
  },
];

const DEIB_CARDS = [
  {
    title: "DEIB council",
    description:
      "Our DEIB Council is actively involved in championing and promoting diversity and inclusion initiatives internally and externally.",
    icon: iconDEIBCouncil,
  },
  {
    title: "DEIB program",
    description:
      "Our DEIB Council plans and maintains an extensive calendar of events, internal resources and offers multiple avenues to get involved.",
  },
  {
    title: "Inclusive hiring",
    description:
      "We practice a structured and inclusive interview process across all roles to support a fair and equitable candidate experience.",
    icon: iconInclusiveHiring,
  },
  {
    title: "Training & education",
    description:
      "We offer resources and continuous anti-bias trainings to our recruiters, managers and interviewers.",
    icon: iconTrainingAndEducation,
  },
];

const BENEFITS = [
  {
    title: "Connection opportunities",
    description: (
      <span>
        Build relationships with colleagues near and far through regular virtual
        meet-ups and special in-person events.
      </span>
    ),
    icon: iconHandshake,
    image: <div className={styles.imgBenefitFirst}></div>,
    classNameImage: "imgBenefitFirstWrap",
  },
  {
    title: "Generous parental leave",
    description: (
      <span>
        Embrace new additions to your family with a supportive parental leave
        policy.
      </span>
    ),

    icon: iconFeedingBottle,
    image: <div className={styles.imgBenefitSecond}></div>,
    classNameImage: "imgBenefitSecondWrap",
  },
  {
    title: "Flexible PTO",
    description: (
      <span>
        Take the time you need, when you need it, with our flexible paid time
        off policy.
      </span>
    ),
    icon: iconPalm,
    image: <div className={styles.imgBenefitThird}></div>,
    classNameImage: "imgBenefitThirdWrap",
  },
  {
    title: "Learning stipend",
    description: (
      <span>
        Further your education and skills with a stipend dedicated to your
        continuous professional development.
      </span>
    ),
    icon: iconBooks,
    image: <div className={styles.imgBenefitFourth}></div>,
    classNameImage: "imgBenefitFourthWrap",
  },
  {
    title: "Global benefits",
    description: (
      <span>
        We prioritize your health and well-being by offering you employer paid
        medical, dental and vision covered health, dental, and vision insurance,
        regardless of where you live in the world.
        <br />
        <br />
        401k, LTD, STD, Life, Pet Insurance, and more available in the US.
      </span>
    ),
    icon: iconGift,
    image: <div className={styles.imgBenefitFifth}></div>,
    classNameImage: "imgBenefitFifthWrap",
  },
];

const CARROUSEL_IMAGES = [
  imgCarousel1,
  imgCarousel2,
  imgCarousel3,
  imgCarousel4,
  imgCarousel5,
  imgCarousel6,
  imgCarousel7,
  imgCarousel8,
  imgCarousel9,
  imgCarousel10,
  imgCarousel11,
  imgCarousel12,
  imgCarousel13,
  imgCarousel14,
  imgCarousel15,
  imgCarousel16,
  imgCarousel17,
  imgCarousel18,
  imgCarousel19,
  imgCarousel20,
  imgCarousel21,
  imgCarousel22,
  imgCarousel23,
  imgCarousel24,
  imgCarousel25,
  imgCarousel26,
  imgCarousel27,
  imgCarousel28,
  imgCarousel29,
  imgCarousel30,
  imgCarousel31,
  imgCarousel32,
  imgCarousel33,
  imgCarousel34,
  imgCarousel35,
];

const INTERVIEW_STEPS = [
  {
    stepLine: iconLine01,
    stepIcon: iconStep01,
    stepIconGrey: iconStep01,
    cardIcon: iconRecruiterScreen,
    cardTitle: "Recruiter screen",
  },
  {
    stepLine: iconLine02,
    stepIcon: iconStep02,
    stepIconGrey: iconStep02Grey,
    cardIcon: iconManagerScreen,
    cardTitle: "Hiring manager screen",
  },
  {
    stepLine: iconLine03,
    stepIcon: iconStep03,
    stepIconGrey: iconStep03Grey,
    cardIcon: iconFunctionalInterview,
    cardTitle: "Peer/cross- functional interviews",
  },
  {
    stepLine: iconLine04,
    stepIcon: iconStep04,
    stepIconGrey: iconStep04Grey,
    cardIcon: iconTechnicalInterview,
    cardTitle: "Technical interview",
    cardLabel: "technical roles",
  },
  {
    stepLine: iconLine042,
    stepIcon: iconStep042,
    stepIconGrey: iconStep04Grey,
    cardIcon: iconHomeAssessment,
    cardTitle: "Take-home assessment",
    cardLabel: "business roles",
  },
  {
    stepLine: iconLine05,
    stepIcon: iconStep05,
    stepIconGrey: iconStep05Grey,
    cardIcon: iconReferenceCheck,
    cardTitle: "Reference checks",
  },
  {
    stepIcon: iconStep06,
    stepIconGrey: iconStep06Grey,
    cardIcon: iconOffer,
    cardTitle: "Offer",
  },
];

const CareersAtSteerPage = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const videoUrl = "https://www.youtube.com/watch?v=07h6YdHbnQY";
  const { isMobile, isTablet, windowWidth } = useScreenSize();
  const isNotPC = windowWidth < SCREEN_TABLET;
  const refOpenPositions = useRef(null);
  const refImagesCarousel = useRef(null);
  const refs = [refImagesCarousel];
  const interviewStepsRefs = INTERVIEW_STEPS.map(() => useRef(null));

  const SUCCESS_CARDS = [
    {
      title:
        "We've experienced remarkable growth in 2023, expanding our team by 60%",
      className: "azure",
      img: isMobile ? imgSuccess1Mob : imgSuccess1,
    },
    {
      title:
        "We align our teams around common goals and cascading OKRs (objectives and key results)",
      description:
        "98.3% of our team members believe they have a clear understanding of their role objectives and expectations.",
      className: "purple",
      img: isMobile ? imgSuccess2Mob : imgSuccess2,
    },
    {
      title:
        "96.5% of our team firmly believes that Steer will succeed in the next three years",
      className: "green",
      img: isTablet ? imgSuccess3Tab : isMobile ? imgSuccess3Mob : imgSuccess3,
    },
    {
      title: "Steer as an employer of choice in our industry",
      description:
        "At Steer, we open doors to meaningful growth and development opportunities, and prioritize your well-being with employee benefits and programs focused on work-life balance, connection and satisfaction.",
      className: "blue",
      img: isNotPC ? imgSuccess4Tab : imgSuccess4,
    },
    {
      title: "We've invested in a top-of-the-line people tech stack",
      description:
        "These tools have helped us streamline our employee and recruiting experience and improved our ability to attract and retain top talent.",
      className: "orange",
      img: isTablet ? imgSuccess5Tab : imgSuccess5,
    },
  ];

  useEffect(() => {
    animateOnScroll(refs.map((ref) => ref.current));
    if (isMobile) {
      animateOnScroll(
        interviewStepsRefs.map((ref) => ref.current),
        {}
      );
    }
  }, []);

  const handleScrollToOpenPositions = () => {
    if (refOpenPositions && refOpenPositions.current) {
      refOpenPositions.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <UpperSection
        sectionName="Careers at Steer"
        textTitle="Join our team"
        description="Explore fulfilling, remote opportunities at Steer and become part of a team building a better future for auto repair."
        clsLeft="CareersAtSteerHero"
        clsRight="CareersImg"
        formClass="Hidden"
        linkTitle="View Open Roles"
        buttonHandler={handleScrollToOpenPositions}
        clsBtn={styles.heroBtn}
        className={styles.heroWrapper}
      >
        {isMobile ? (
          <StaticImage
            src="../../assets/images/Careers/hero-img-mob.png"
            alt="happy people"
            className={styles.heroMobile}
            loading="eager"
            quality={100}
            objectFit="contain"
          />
        ) : (
          <div className={styles.imagesWrap}>
            <StaticImage
              src="../../assets/images/Careers/hero-image-1.png"
              alt="men in circle"
              className={styles.photo1}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/Careers/hero-image-2.png"
              alt="woman in triangle"
              className={styles.photo2}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/Careers/hero-image-3.png"
              alt="woman in ellipse"
              className={styles.photo3}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
          </div>
        )}
      </UpperSection>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <SectionTitle title="Our success comes from our talented team and happy customers" />
          <div className={styles.cardsContainer}>
            {SUCCESS_CARDS.map(
              ({ title, description, className, img }, i) =>
                i <= 1 && (
                  <OurSuccessCard
                    title={title}
                    description={description}
                    key={i}
                    className={className}
                    img={img}
                  />
                )
            )}
          </div>
          <div className={styles.cardsContainer}>
            {SUCCESS_CARDS.map(
              ({ title, description, className, img }, i) =>
                i > 1 && (
                  <OurSuccessCard
                    title={title}
                    description={description}
                    key={i}
                    className={className}
                    variant="Secondary"
                    img={img}
                  />
                )
            )}
          </div>
          <OurValues cards={OUR_VALUES_CARDS} />
          <SectionTitle
            title="Diversity, equity, inclusion & belonging"
            description="As a remote, international team made up of a wide range of experiences, languages, nationalities, and cultures, we are deeply committed to Diversity, Equity, Inclusion & Belonging, and creating a culture where everyone is empowered to be their authentic selves."
            className={styles.thinnerSectionTitle}
          />
          <div className={styles.diebCards}>
            {DEIB_CARDS.map(({ title, description, icon }, i) => (
              <Card
                title={title}
                description={description}
                key={i}
                variant="Careers"
                icon={icon}
              />
            ))}
          </div>
        </div>
      </div>
      <ImagesCarousel images={CARROUSEL_IMAGES} refProp={refImagesCarousel} />
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <SectionTitle
            title="Why you'll love working with us"
            description="Connect, learn, and grow in a flexible work environment."
          />
          <div className={styles.benefitsContainer}>
            {BENEFITS.map(
              ({ title, icon, description, image, classNameImage }, i) => (
                <Benefit
                  title={title}
                  icon={icon}
                  description={description}
                  img={image}
                  key={i}
                  variant={i % 2 ? "Reverse" : ""}
                  cnImg={classNameImage}
                />
              )
            )}
          </div>
          <SectionTitle
            title="Interview process"
            description="Our interview process is designed to be transparent and respectful of every candidate's time. We aim to complete each process in up to 4 interview stages, and can tailor the pace to your needs. We believe interviewing is a two-way street, so come prepared to learn about us just as much as we learn about you!"
            className={styles.interviewSectionTitle}
          />
          {isMobile && (
            <div className={styles.interviewMobile}>
              {INTERVIEW_STEPS.map(
                (
                  {
                    stepLine,
                    stepIcon,
                    stepIconGrey,
                    cardIcon,
                    cardTitle,
                    cardLabel,
                  },
                  i
                ) => (
                  <div key={i} className={styles.step}>
                    <div
                      className={styles.animationTrigger}
                      ref={interviewStepsRefs[i]}
                      data-animateparent
                    />
                    <img
                      src={stepIconGrey}
                      className={styles.iconGrey}
                      alt="icon"
                    />
                    <img src={stepIcon} className={styles.icon} alt="icon" />
                    <img
                      src={iconLineGrey}
                      className={styles.lineGrey}
                      alt="icon"
                    />
                    <img src={stepLine} className={styles.line} alt="icon" />
                    <div className={styles.card}>
                      <img src={cardIcon} className={styles.icon} alt="icon" />
                      <div className={styles.title}>
                        {cardLabel && (
                          <div className={styles.label}>{cardLabel}</div>
                        )}
                        {cardTitle}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
      {!isMobile && (
        <div className={styles.interviewWrapper}>
          <div className={styles.interviewProcess}></div>
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <SectionTitle
            title="2023 year in review"
            description="In 2023, we focused on building a strong product that helps our auto shop community connect with their customers in a meaningful way, and empowering our team to support it."
            className={styles.videoSectionTitle}
          />
          <VideoSection
            videoUrl={videoUrl}
            isFullscreen={isFullscreen}
            handleFullscreen={setIsFullscreen}
            isMobile={isMobile}
          />
          <div ref={refOpenPositions} />
          <SectionTitle title="Open positions" />
          <JobBoard />
        </div>
      </div>
    </>
  );
};

export default CareersAtSteerPage;

type SectionTitleProps = {
  title: string;
  description?: string;
  className?: any;
};

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  description,
  className = "",
}) => {
  return (
    <div className={`${styles.sectionTitle}  ${className}`}>
      <div className={styles.title}>{title}</div>
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
};

type OurSuccessCardProps = {
  title: string;
  description?: string;
  className?: string;
  variant?: string;
  img?: string;
};

const OurSuccessCard: React.FC<OurSuccessCardProps> = ({
  title,
  description,
  className = "",
  variant,
  img,
}) => {
  return (
    <div
      className={`${styles.successCard}  ${styles[className]} ${
        styles[`variant${variant}`]
      }`}
    >
      <div className={styles.successTextWrapper}>
        <div className={styles.successCardTitle}>{title}</div>
        {description && (
          <div className={styles.successCardDescription}>{description}</div>
        )}
      </div>
      <div className={styles.successImage}>
        <img src={img} alt="img" />
      </div>
    </div>
  );
};

type OurValuesProps = {
  cards: Array<{ title: string; description: string; icon?: any }>;
};

const OurValues: React.FC<OurValuesProps> = ({ cards }) => {
  return (
    <div className={styles.valuesWrapper}>
      <div className={styles.quotationContainer}>
        <div className={styles.quotationTitle}>Our values</div>
        <div className={styles.quotationMarker}></div>
        <div className={styles.quotation}>
          We believe in promoting a culture that rewards excellence, kindness,
          and creativity at every level.
        </div>
        <div className={styles.autor}>
          Parker Swift,
          <br />
          CEO, Steer by Mechanic Advisor
        </div>
      </div>
      <div className={styles.valuesCards}>
        {cards.map((card, i) => (
          <Card {...card} key={i} variant="Additional" />
        ))}
      </div>
    </div>
  );
};

type BenefitProps = {
  title: string;
  description?: JSX.Element;
  img?: JSX.Element;
  icon?: string;
  variant?: string;
  cnImg?: string;
};

const Benefit: React.FC<BenefitProps> = ({
  title,
  icon,
  description,
  img,
  variant,
  cnImg = "",
}) => {
  return (
    <div className={`${styles.benefit} ${styles[`variant${variant}`]}`}>
      <div className={`${styles.benefitImage} ${styles[cnImg]}`}>{img}</div>
      <div className={styles.benefitText}>
        <div className={styles.benefitTitle}>
          {title}
          <img src={icon} className={styles.benefitIcon} alt="icon" />
        </div>
        <div className={styles.benefitDescription}>{description}</div>
      </div>
    </div>
  );
};

type VideoProps = {
  videoUrl: string;
  isFullscreen: boolean;
  handleFullscreen: (arg: boolean) => void;
  isMobile: boolean;
};

const VideoSection: React.FC<VideoProps> = ({
  videoUrl,
  isFullscreen,
  handleFullscreen,
  isMobile,
}) => {
  const videoPlayerRef = useRef(null);

  const handleClickPreview = () => {
    handleFullscreen(true);
  };

  const handleOnClose = () => {
    handleFullscreen(false);
    videoPlayerRef.current.showPreview();
  };

  return (
    <div
      className={cn(styles.videoWrapper, {
        [styles.fullScreenVideo]: isFullscreen,
      })}
    >
      {isFullscreen && (
        <div
          className={styles.buttonWrapper}
          onClick={() => {
            handleOnClose();
          }}
        >
          <div className={styles.closeIcon}></div>
        </div>
      )}

      <ReactPlayer
        ref={videoPlayerRef}
        url={videoUrl}
        light={
          <img
            src={isMobile ? imgVideoPreviewMob : imgVideoPreview}
            className={styles.preview}
          />
        }
        playing={isFullscreen}
        config={{
          youtube: {
            playerVars: {
              controls: 1,
              playsinline: 1,
              rel: 0,
              iv_load_policy: 3,
              hd: 1,
              showinfo: 0,
            },
          },
        }}
        className={styles.videoPlayer}
        onClickPreview={() => handleClickPreview()}
        onEnded={() => handleOnClose()}
        playIcon={<PlayButton />}
      ></ReactPlayer>
      {isFullscreen && <RemoveScrollBar gapMode="padding" />}
    </div>
  );
};

const PlayButton = () => {
  return (
    <div className={styles.playButton}>
      <div className={styles.buttonCircle}>
        <div className={styles.buttonTriangle}></div>
      </div>
    </div>
  );
};

type ImagesProps = {
  images: Array<string>;
  refProp: React.RefObject<HTMLDivElement>;
};

const ImagesCarousel: React.FC<ImagesProps> = ({ images, refProp }) => {
  return (
    <div className={styles.imgsWrapper} ref={refProp}>
      <div className={styles.imagesLine}>
        {images.map((img, i) => (
          <div
            key={i + "1"}
            className={cn(styles.imgItem, {
              [styles.vertical]: i % 2,
            })}
            style={{ background: `url(${img}) center/cover no-repeat` }}
          ></div>
        ))}
        {images.map((img, i) => (
          <div
            key={i + "2"}
            className={cn(styles.imgItem, {
              [styles.vertical]: i % 2,
            })}
            style={{ background: `url(${img}) center/cover no-repeat` }}
          ></div>
        ))}
      </div>
    </div>
  );
};
