import React, { useState, useEffect, useRef } from "react";
import * as styles from "./PricingPlans.module.scss";
import cn from "classnames";
import UpperSection from "../Shared/UpperSection";
import { VSplit } from "../Shared/Divider";
import SwitchButton from "../Shared/Switch";
import Select from "../Shared/SelectInput";
import Tooltip from "../Shared/Tooltip";
import SubscribePopup from "../SubscribePopup";
import {
  ANALYTICS_EVENTS,
  addAnalyticsEvent,
} from "../../services/analyticsService";
import IconChecked from "../../assets/icons/PricingPlans/i-pricing-checked.svg";
import IconDeclined from "../../assets/icons/PricingPlans/i-pricing-declined.svg";
import IconArrow from "../../assets/icons/i-arrow-up-active.svg";
import iconArrow from "../../assets/icons/i-arrow-up-grey.svg";
import iconArrowLight from "../../assets/icons/i-arrow-up-light-grey.svg";
import IconInfo from "../../assets/icons/i-info.svg";
import IconInfoHover from "../../assets/icons/i-info-blue.svg";
import starsIcon from "../../assets/icons/i-stars-green.svg";
import smileIcon from "../../assets/icons/i-smile-green.svg";
import IconTier1 from "../../assets/icons/PricingPlans/i-tier-1.svg";
import IconTier3 from "../../assets/icons/PricingPlans/i-tier-3.svg";

const SHOP_SIZES_LIST = [
  {
    value: "<100 car count/month",
    shopSize: "s",
  },
  {
    value: "100-199 car count/month",
    shopSize: "s",
  },
  {
    value: "200-299 car count/month",
    shopSize: "s",
  },
  {
    value: "300-399 car count/month",
    shopSize: "l",
  },
  {
    value: "400-499 car count/month",
    shopSize: "l",
  },
  {
    value: "500-599 car count/month",
    shopSize: "l",
  },
  {
    value: "600-699 car count/month",
    shopSize: "l",
  },
  {
    value: "700-799 car count/month",
    shopSize: "l",
  },
  {
    value: "800-899 car count/month",
    shopSize: "l",
  },
  {
    value: "900-999 car count/month",
    shopSize: "l",
  },
  {
    value: "1000> car count/month",
    shopSize: "l",
  },
];

const PRICING_PLANS = [
  {
    shopSizeTitle: "Contact",
    price: "$349",
    agreementPrice: "$294",
    campaign: [
      "1000 campaign text messages/month",
      "Unlimited 2-way direct text & email messaging",
      "Unlimited appointment & service reminders",
    ],
    size: "s",
    icon: IconTier1,
  },
  {
    shopSizeTitle: "Engage",
    price: "$479",
    agreementPrice: "$404",
    campaign: [
      "Unlimited text campaign sends/month",
      "Unlimited 2-way direct text & email messaging",
      "Unlimited appointment & service reminders",
    ],
    size: "l",
    icon: IconTier3,
  },
];

const SCALE_TOOLS = [
  "Unlimited 2-way direct text & email messaging",
  "Unlimited email campaign sends",
  "Text from local phone number",
  "Website appointment scheduler",
  "Reputation management",
  "Custom text & email campaigns",
  "Appointment & service reminders",
  "Customer support",
  "Shop, customer & vehicle analytics",
  "Automated maintenance reminders",
  "Custom customer segments",
  "Advanced email editor",
  "Automated deferred service reminders",
  "Automated appointment reminders",
  "Personalized customer vehicle web pages",
  "Customer location targeting",
  "Select any review source",
  "Shop management system integration",
  "Onboarding & campaign setup",
  "Role-based user permissions",
];

const PricingPlans = ({}) => {
  const [isChecked, setIsChecked] = useState(true);
  const [shopSize, setShopSize] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const FREQUENTLY_ASKED_QUESTIONS = [
    {
      question: "What are the payment options?",
      answer: (
        <span>
          We provide two flexible payment options: a 12-month agreement paid in
          full up-front or a monthly plan with no commitment.
        </span>
      ),
    },
    {
      question: "Is a contract required?",
      answer: (
        <span>
          Nope! For annual plans, you pay a discounted rate in full up-front,
          with no contract required.
        </span>
      ),
    },
    {
      question: "Can I cancel or change my plan at anytime?",
      answer: (
        <span>
          The process of canceling your subscription varies from plan to plan.
          <br />
          <span className={styles.paragraph}>
            <b>Monthly Subscriptions</b>
            <br />
            If you have a month-to-month subscription and choose to end your
            service, the cancellation will go into effect 30 days after your
            next bill. Your service will automatically terminate without renewal
            30 days after your final invoice date. We cannot prorate
            subscriptions if you wish to discontinue early.
            <br />
          </span>
          <span className={styles.paragraph}>
            <b>Annual Subscriptions</b>
            <br />
            For annual subscriptions, your contract will automatically renew
            each year. You must notify us 30 days before auto-renewal to change
            your plan. We will provide notice of rate changes 30 days prior to
            annual renewal if applicable.
            <br />
          </span>
          <span className={styles.paragraph}>
            Should you have any unique subscription change requests, please get
            in touch with us.
          </span>
        </span>
      ),
    },
    {
      question: "What do the onboarding & integration fees cover?",
      answer: (
        <span>
          Our data and setup fees cover your initial integration with your
          preferred shop management system. It includes the matching, cleaning,
          and validating of your data between the two systems. To ensure
          accuracy, we also confirm your customer contact information, including
          phone numbers, emails, and mailing addresses. Our onboarding team will
          create up to nine different base campaigns to get you started as
          quickly as possible.
        </span>
      ),
    },
    {
      question: "Does Steer integrate with my shop management system?",
      answer: (
        <span>
          We currently integrate with 20+ different shop management systems. See
          if your preferred shop management system is available{" "}
          <a
            href="https://steercrm.com/shop-management-integrations"
            className={styles.link}
          >
            here
          </a>
          .
        </span>
      ),
    },
    {
      question: "Can I buy additional text messages?",
      answer: (
        <span>
          Absolutely! You'll need to upgrade your plan to increase your text
          message count. To do so, go to <b>Settings</b> → <b>Pricing Plans</b>{" "}
          → then select the next larger plan.
        </span>
      ),
    },
    {
      question: "How do I sign-up for Steer?",
      answer: (
        <span>
          <span className={styles.link} onClick={() => handlePopupOpen()}>
            Book a demo{" "}
          </span>
          to speak with our sales team to sign up for Steer. Our team will work
          with you to understand the specific needs of your shop and provide a
          guided demo to get you started.
        </span>
      ),
    },
    {
      question: "How do I pay for Steer?",
      answer: (
        <span>
          We offer multiple payment options, including credit or debit cards and
          direct payment through a bank routing and account number.
        </span>
      ),
    },
    {
      question: "How often does your Customer Success team check-in?",
      answer: (
        <span>
          <span>
            Your Steer Account Manager will work closely with you for your first
            two months on Steer. Once your account is live and your team is
            comfortable with our platform, you can contact your dedicated
            Customer Success representative for additional support*.
          </span>
          <span className={styles.footnote}>For Engage level plan only.</span>
        </span>
      ),
    },
    {
      question: "When is my bill date?",
      answer: (
        <span>
          Your payment date is the same each month and corresponds with the date
          you started your plan.
        </span>
      ),
    },
    {
      question: "Is there a limit to the number of users on my account?",
      answer: (
        <span>
          You can add unlimited users to your Steer account at no additional
          charge.
        </span>
      ),
    },
    {
      question: "Is there a limit to the number of devices I can use on Steer?",
      answer: (
        <span>
          You can use Steer on unlimited computers, phones, or tablets.
        </span>
      ),
    },
  ];

  const handleShopSizeSelect = (shopSize: {
    shopSize: string;
    value: string;
  }) => {
    setShopSize(shopSize);
  };

  useEffect(() => {
    if (document !== undefined) {
      if (isPopupOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "overlay";
      }
    }
  }, [isPopupOpen]);

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handlePopupOpen = () => {
    addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessClick);
    setIsPopupOpen(true);
  };

  return (
    <>
      <UpperSection
        sectionName="Pricing"
        textTitle="Choose your plan"
        description="Choose your plan based on how many cars you service each month."
        clsLeft="PricingPlansHero"
        clsRight="PricingPlansHeroImg"
        formClass="Hidden"
        className={styles.hero}
      >
        <TermAndShopSizeSelect
          onSwitchToggle={() => setIsChecked(!isChecked)}
          isChecked={isChecked}
          shopSize={shopSize}
          onShopSizeChange={(e) => handleShopSizeSelect(e)}
        />
      </UpperSection>
      <div className={styles.wrapper}>
        <div className={styles.plansSections}>
          <div className={styles.plansContainer}>
            {PRICING_PLANS.map((plan, i) => (
              <PricePlan
                isChecked={isChecked}
                shopSizeTitle={plan.shopSizeTitle}
                icon={plan.icon}
                price={plan.price}
                agreementPrice={plan.agreementPrice}
                campaign={plan.campaign}
                key={i}
                isRecommended={shopSize.shopSize === plan.size}
                onClick={handlePopupOpen}
              />
            ))}
          </div>
          <div className={styles.toolsWrapper}>
            <div className={styles.toolsTitle}>
              The tools you need to scale, included with every plan
            </div>
            <div className={styles.toolsContainer}>
              {SCALE_TOOLS.map((tool, i) => (
                <div className={styles.toolItem} key={i}>
                  <img className={styles.icon} src={IconChecked}></img>
                  <div className={styles.toolTitle}>{tool}</div>
                </div>
              ))}
            </div>
          </div>
          <div
            className={cn(styles.plansComparison, {
              [styles.collapsed]: isCollapsed,
            })}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            Compare Plans and Features
            <img className={styles.arrowIcon} src={IconArrow}></img>
          </div>
          <div className={styles.plansComparisonWrapper}>
            {!isCollapsed && <div className={styles.poligon1}></div>}
            <PlansComparison isCollapsed={isCollapsed} isChecked={isChecked} />
          </div>
        </div>
        <div className={styles.banner}>
          <div className={styles.card}>
            <img
              className={styles.bannerStars}
              src={starsIcon}
              alt="rating icon"
            />
            <div className={styles.bannerDivider}></div>
            <img
              className={styles.bannerSmile}
              src={smileIcon}
              alt="smile icon"
            />
            <div className={styles.bannerText}>
              Increased profits, less headaches and customer service at its
              finest! Mechanic Advisor has been one of the best decisions we’ve
              made. We used one of their competitors for many years, and not
              only does Mechanic Advisor offer much more, they do so at a lesser
              price point! After 39 years in business, we just wish there were
              more vendors like Mechanic Advisor!
            </div>
            <div className={styles.bannerAuthor}>Dale Fout</div>
            <div className={styles.quotesIcon}></div>
          </div>
        </div>
        <div className={styles.FAQSections}>
          <div className={styles.info}>
            <div className={styles.title}>Frequently asked questions</div>
          </div>
          <div className={styles.main}>
            {FREQUENTLY_ASKED_QUESTIONS.map((el, i) => (
              <Question {...el} key={i} />
            ))}
          </div>
          <div className={styles.poligon2}></div>
        </div>
      </div>
      {isPopupOpen && <SubscribePopup close={handlePopupClose} />}
    </>
  );
};

export default PricingPlans;

type TermAndShopSizeSelectProps = {
  isChecked: boolean;
  shopSize: {
    shopSize: string;
    value: string;
  };
  onShopSizeChange: (e: { shopSize: string; value: string }) => void;
  onSwitchToggle: () => void;
};

const TermAndShopSizeSelect: React.FC<TermAndShopSizeSelectProps> = ({
  onSwitchToggle,
  isChecked,
  shopSize,
  onShopSizeChange,
}) => {
  const tooltipMessage =
    "Choose your average monthly car count and we will recommend the plan that is right for your shop";
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.title}>Subscription term</div>
        <div className={styles.switchWrapper}>
          <SwitchButton
            isChecked={isChecked}
            onChange={() => onSwitchToggle()}
            leftLabel="Monthly"
            rightLabel="Annual - save up to"
            highlightedText="15%"
          />
        </div>
      </div>
      <VSplit className={styles.divider} />
      <div></div>
      <div>
        <div className={styles.title}>
          Average monthly car count
          <Tooltip
            className={styles.infoTooltipWrap}
            tooltip={tooltipMessage}
            icon={<img src={IconInfo} alt="info icon" />}
            hoverIcon={<img src={IconInfoHover} alt="info icon" />}
            clsTooltip={styles.infoTooltip}
            isPreHidden
          />
        </div>
        <Select
          options={SHOP_SIZES_LIST}
          onChange={(shopSize) => onShopSizeChange(shopSize)}
          value={shopSize.value}
          initialValue="Select Car Count"
        />
      </div>
    </div>
  );
};

type PricePlanProp = {
  isChecked: boolean;
  shopSize?: { id: number; value: string };
  shopSizeTitle: string;
  icon: any;
  price: string;
  agreementPrice: string;
  campaign: [string];
  isRecommended: boolean;
  onClick: () => void;
};

const PricePlan: React.FC<PricePlanProp> = ({
  isChecked,
  shopSizeTitle,
  icon,
  price,
  agreementPrice,
  campaign,
  isRecommended,
  onClick,
}) => {
  return (
    <div
      className={cn(styles.planWrapper, {
        [styles.recommended]: isRecommended,
      })}
    >
      <img src={icon} alt="icon" className={styles.icon} />
      <div className={styles.shopTitle}>{shopSizeTitle}</div>
      <div className={styles.price}>
        {isChecked ? agreementPrice : price}
        <span>/ mo</span>
      </div>
      {isChecked && (
        <div className={styles.agreement}>with 12-month agreement</div>
      )}
      <div className={styles.onboardingBonus}>
        + $199 onboarding & integration fee
      </div>
      <div className={styles.splitter} />
      {campaign.map((item, i) => (
        <div className={styles.campaign} key={i}>
          {item}
        </div>
      ))}
      <button className={styles.demoBtn} onClick={() => onClick()}>
        Get a Demo
      </button>
    </div>
  );
};

const PlansComparison = ({ isCollapsed = false, isChecked }) => {
  const tableRef = useRef(null);
  const [isHeaderFixed, setHeaderFixed] = useState(false);

  useEffect(() => {
    const handlePageScroll = () => {
      const { top, bottom } = tableRef.current.getBoundingClientRect();

      if (!isHeaderFixed && top < -50 && bottom > 200) {
        setHeaderFixed(true);
      } else if (isHeaderFixed && (top >= -50 || bottom <= 200)) {
        setHeaderFixed(false);
      }
    };

    handlePageScroll();
    window.addEventListener("scroll", handlePageScroll);

    return () => {
      window.removeEventListener("scroll", handlePageScroll);
    };
  }, [isHeaderFixed]);

  return (
    <div
      ref={tableRef}
      className={cn(styles.tableWrapper, { [styles.collapsed]: isCollapsed })}
    >
      <div
        className={cn(styles.tableHeader, { [styles.fixed]: isHeaderFixed })}
      >
        <TableHeader
          price={`${
            isChecked ? PRICING_PLANS[0].agreementPrice : PRICING_PLANS[0].price
          }/mo`}
          shopTitle={PRICING_PLANS[0].shopSizeTitle}
          condition={isChecked}
        />
        <TableHeader
          price={`${
            isChecked ? PRICING_PLANS[1].agreementPrice : PRICING_PLANS[1].price
          }/mo`}
          shopTitle={PRICING_PLANS[1].shopSizeTitle}
          condition={isChecked}
        />
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableSectionHeader}>Campaigns</div>
        <div className={styles.tableSectionWrapper}>
          <TableRow
            title="Text campaign sending limits"
            firstTitle="1000 sends/month"
            secontTitle="Unlimited sends/month"
          />
          <TableRow
            title="Email campaign sending limits"
            firstTitle="Unlimited"
            secontTitle="Unlimited"
          />
          <TableRow title="Custom customer segments" checkedLength={2} />
          <TableRow title="Create draft campaigns" checkedLength={2} />
          <TableRow title="Advanced email editor" checkedLength={2} />
          <TableRow title="Automated service reminders" checkedLength={2} />
          <TableRow title="Customer vehicle landing pages" checkedLength={2} />
        </div>
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableSectionHeader}>Messaging</div>
        <div className={styles.tableSectionWrapper}>
          <TableRow
            title="2-way direct text & email messaging"
            firstTitle="Unlimited"
            secontTitle="Unlimited"
          />
          <TableRow title="Text from local phone number" checkedLength={2} />
          <TableRow
            title="Text from shops number"
            firstTitle="Add-on"
            secontTitle="Add-on"
          />
        </div>
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableSectionHeader}>Appointments</div>
        <div className={styles.tableSectionWrapper}>
          <TableRow title="Website appointment scheduler" checkedLength={2} />
          <TableRow title="Automated appointment reminders" checkedLength={2} />
          <TableRow title="Calendar view" checkedLength={2} />
        </div>
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableSectionHeader}>Dashboard & Analytics</div>
        <div className={styles.tableSectionWrapper}>
          <TableRow title="Shop activity" checkedLength={2} />
          <TableRow title="Customer activity" checkedLength={2} />
          <TableRow title="Vehicle make breakdown" checkedLength={2} />
          <TableRow title="Customer location map" checkedLength={2} />
        </div>
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableSectionHeader}>Reputation</div>
        <div className={styles.tableSectionWrapper}>
          <TableRow
            title="Choose and track review sources"
            firstTitle="Google, Facebook, Yelp"
            secontTitle="Google, Facebook, Yelp"
          />
          <TableRow
            title="Synced every day"
            firstTitle="Daily"
            secontTitle="Daily"
          />
          <TableRow title="Reputation analytics" checkedLength={2} />
        </div>
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableSectionHeader}>Integrations</div>
        <div className={styles.tableSectionWrapper}>
          <TableRow
            title="Shop Management System integration"
            checkedLength={2}
          />
          <TableRow
            title="Data clean-up & customer matching"
            checkedLength={2}
          />
        </div>
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableSectionHeader}>Service & Support</div>
        <div className={styles.tableSectionWrapper}>
          <TableRow title="Onboarding & campaign setup" checkedLength={2} />
          <TableRow title="Customer support" checkedLength={2} />
          <TableRow
            title="Dedicated customer success rep"
            checkedLength={1}
            isDeclined
          />
          <TableRow title="Advanced user permissions" checkedLength={2} />
        </div>
      </div>
    </div>
  );
};

const TableHeader = ({
  price = "",
  shopTitle = "",
  campaign = "",
  condition,
}) => {
  return (
    <div className={styles.tableHeaderCard}>
      <div className={styles.tableHeaderTitle}>{shopTitle}</div>
      <div className={styles.tableHeaderPrice}>{price}</div>
      {condition && (
        <div className={styles.tableHeaderAgreement}>
          with 12-month agreement
        </div>
      )}
      <div className={styles.tableHeaderOnboardingBonus}>
        + $199 onboarding & integration fee
      </div>
    </div>
  );
};

type TableRowProp = {
  title: string;
  checkedLength?: number;
  isDeclined?: boolean;
  firstTitle?: string;
  secontTitle?: string;
  thirdTitle?: string;
};

const TableRow: React.FC<TableRowProp> = ({
  title,
  checkedLength,
  isDeclined,
  firstTitle,
  secontTitle,
}) => {
  return (
    <div className={styles.tableRow}>
      <div className={styles.tableRowTitle}>{title}</div>
      <div className={styles.tableRowData}>
        {isDeclined && (
          <div>
            <img className={styles.tableRowIcon} src={IconDeclined}></img>
          </div>
        )}
        {checkedLength &&
          Array.from({ length: checkedLength }, (_, index) => {
            return (
              <div key={index}>
                <img className={styles.tableRowIcon} src={IconChecked}></img>
              </div>
            );
          })}
        {firstTitle && (
          <>
            <div>{firstTitle}</div>
            <div>{secontTitle}</div>
          </>
        )}
      </div>
    </div>
  );
};

type QuestionProps = {
  question: String;
  answer: JSX.Element;
};

const Question: React.FC<QuestionProps> = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleIsExpanded = () => setIsExpanded((prev) => !prev);
  const [hover, setHover] = useState(false);
  return (
    <div
      className={cn(styles.questionWrapper, { [styles.expanded]: isExpanded })}
    >
      <div
        className={styles.question}
        onClick={toggleIsExpanded}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <div
          className={cn(styles.text, {
            [styles.textLight]: hover,
          })}
        >
          {question}
        </div>
        {hover ? (
          <img src={iconArrowLight} alt="icon" className={styles.arrowIcon} />
        ) : (
          <img src={iconArrow} alt="icon" className={styles.arrowIcon} />
        )}
      </div>
      <div className={styles.answer}>{answer}</div>
    </div>
  );
};
