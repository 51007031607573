import React, { useEffect, useRef } from "react";
import * as styles from "./ToolsNavigation.module.scss";
import {
  iconToolsSchedulingServices,
  iconToolsMarketingTools,
  iconToolsReputationManagement,
  iconToolsFleetDiagnostics,
} from "../../../assets/icons/Home";
import {
  animateOnScroll,
  animateOnScrollByPercentage,
} from "../../../services/animationService";

const TOOLS = [
  {
    title: "Scheduling services",
    description:
      "Our automated scheduling system allows customers to access your real-time calendar and schedule appointments directly into your shop management system.",
    icon: iconToolsSchedulingServices,
  },
  {
    title: "Essential marketing tools",
    description:
      "Steer provides a complete suite of marketing automation tools that help build relationships with customers and increase customer loyalty and retention.",
    icon: iconToolsMarketingTools,
  },
  {
    title: "Reputation management",
    description:
      "Our reputation builder helps you collect and share high-quality reviews written by real customers to increase your credibility and online appearance.",
    icon: iconToolsReputationManagement,
  },
  {
    title: "Fleet diagnostics",
    description:
      "Give your commercial fleets accounts the power of telematics. Remotely detect issues, provide real-time service reminders, and remotely view vehicle diagnostic information.",
    icon: iconToolsFleetDiagnostics,
  },
];

export const ToolsNavigation = () => {
  const titleRef = useRef(null);
  const card1Ref = useRef(null);
  const card2Ref = useRef(null);
  const card3Ref = useRef(null);
  const card4Ref = useRef(null);
  const cardsRefs = [card1Ref, card2Ref, card3Ref, card4Ref];
  useEffect(
    () => animateOnScrollByPercentage(cardsRefs.map((ref) => ref.current)),
    []
  );

  useEffect(() => animateOnScroll([titleRef.current]), []);

  const scrollToTool = (i: number) =>
    document
      .querySelectorAll("[class^=Promotions-module--promotions]")[0]
      .children[i].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

  return (
    <div className={styles.wrapper}>
      <div className={styles.title} ref={titleRef}>
        Innovative marketing & communication tools for your shop
      </div>
      <div className={styles.cardsContainer}>
        {TOOLS.map((tool, i) => (
          <Card
            {...tool}
            key={i}
            onClick={() => scrollToTool(i)}
            animationRef={cardsRefs[i]}
          />
        ))}
      </div>
    </div>
  );
};

type Tool = {
  title: string;
  description: string;
  icon: any;
  onClick: () => void;
  animationRef: any;
};

const Card: React.FC<Tool> = ({
  title,
  description,
  icon,
  onClick,
  animationRef,
}) => {
  return (
    <div className={styles.card} onClick={onClick} ref={animationRef}>
      <span className={styles.number} />
      <div
        className={styles.icon}
        style={{ background: `url(${icon}) center no-repeat` }}
      ></div>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};
