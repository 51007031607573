import React from "react";
import * as styles from "./Communicate.module.scss";
import UpperSection from "../../Shared/UpperSection";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../../hooks";

export const Communicate = () => {
  const { windowWidth } = useScreenSize();
  const areMessagesShown = windowWidth > 1023;

  return (
    <UpperSection
      sectionName="Text Messaging"
      textTitle="Auto shop text messaging made easy"
      description="Connect with your customers with auto repair shop texting."
      clsLeft="textMessagingDistance"
      clsRight="imagesWrapperDistance"
      formClass="UpperSectionForm"
    >
      <div className={styles.imagesWrap}>
        <StaticImage
          src="../../../assets/images/TextMessaging/photo-1.png"
          alt="repair master"
          className={styles.photo1}
          loading="eager"
          quality={100}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/message-icon.png"
          alt="message icon"
          className={styles.messageIcon}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/rectangle.png"
          alt="rectangle"
          className={styles.rectangle}
          style={{
            position: "absolute",
          }}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/photo-3.png"
          alt="vehicle audit"
          className={styles.photo2}
          loading="eager"
          quality={100}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/photo-2.png"
          alt="driver"
          className={styles.photo3}
          loading="eager"
          quality={100}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/hexagon.png"
          alt="hexagon"
          className={styles.hexagon}
          style={{
            position: "absolute",
          }}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/photo-4.png"
          alt="text messaging"
          className={styles.photo4}
          loading="eager"
          quality={100}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/ring-icon.png"
          alt="ring"
          className={styles.ringIcon}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/circle.png"
          alt="circle"
          className={styles.circle}
          style={{
            position: "absolute",
          }}
        />
        {areMessagesShown && (
          <>
            <StaticImage
              src="../../../assets/images/TextMessaging/message-1.png"
              alt="message"
              className={styles.message1}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../../assets/images/TextMessaging/message-2.png"
              alt="message"
              className={styles.message2}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
          </>
        )}
      </div>
    </UpperSection>
  );
};
